import { FC, useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router';
import { Loader } from '../common/Loader';
import {
  invitationsApi,
  supplierEmployeeApi,
  visitationApi,
} from '../../utils/api';
import { useTranslation } from 'react-i18next';
import {
  getCustomError,
  setErrorState,
  setErrorStateSnacks,
} from '../../utils/error';
import { ErrorStateType } from '../../models/common';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { Form, FormikProps } from 'formik';
import {
  InvitationTrainingResponse,
  ReferenceDto,
  TourniquetResponse,
  VisitationPinDto,
} from '@qcs/safety-client';
import { InvitationWorkplaceDto } from '../../models/invitation';
import {
  getFirstLastNameObj,
  invitationTrainingIsCompleted,
} from '../../utils/format';
import _ from 'lodash';
import styled from '@emotion/styled';
import { SubmitButton } from '../common/form/SubmitButton';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import {
  tourniquetGetInvitationPerson,
  tourniquetLoadData,
} from '../tourniquet/tourniquetFunctions';
import { selectTourniquetIdentity, tourniquetIdentityActions } from '../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { SaveError } from '../common/SaveError';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { visitationListActions } from '../../store/entities/visitationList';
import { invitationListActions } from '../../store/entities/invitationList';
import { invitationTypeVisitListActions } from '../../store/entities/invitationTypeVisitList';
import { useBack } from '../../hooks/useBack';
import { useSelector } from 'react-redux';

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gap: '0.5rem',
  gridTemplateColumns: 'auto',
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'max-content auto',
  },
}));

interface VisitationVisitDetailFormData {
  title: string;
  invitationName: string;
  personId: string;
  name: string;
  lastName: string;
  company: string;
  employee: ReferenceDto;
  establishments: Array<ReferenceDto>;
  workplaces: Array<InvitationWorkplaceDto>;
  activities: Array<ReferenceDto>;
  phone: string;
  email?: string;
  pin: string;
  trainings: InvitationTrainingResponse[];
  note?: string;
}

export const VisitationVisitDetailForm: FC = () => {
  const { t } = useTranslation();
  const { backTo, goBack } = useBack('/visitation');
  const { invitationId } = useParams();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState<ErrorStateType>();
  const [data, setData] = useState<VisitationVisitDetailFormData>();
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const dispatch = useAppDispatch();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const tourniquetIdentity = useSelector(selectTourniquetIdentity);
  const formikRef = useRef<FormikProps<VisitationVisitDetailFormData> | null>(null);

  useEffect(() => {
    if (tourniquetIdentity?.data?.invitation?.id !== invitationId) {
      dispatch(tourniquetIdentityActions.default());
      dispatch(tourniquetTrainingsActions.default());
    }
  }, [dispatch, invitationId, tourniquetIdentity?.data?.invitation?.id]);

  useEffect(() => {
    (async () => {
      try {
        const invitationRes = await invitationsApi.getInvitationById(
          invitationId!
        );
        const invitation = invitationRes.data;
        const person = invitation.persons![0];

        const pinRes = await supplierEmployeeApi.revealPin(
          person.employeeId!,
          invitationId
        );

        const tourniquetInvitation = tourniquetIdentity?.data?.invitation;


        setData({
          title: invitation.name ?? '',
          invitationName: invitation.name ?? '',
          personId: person.id ?? '',
          name: person.name,
          lastName: person.lastName,
          company: invitation.supplier?.name ?? '',
          employee: {
            id: invitation.employee?.id ?? '',
            name: getFirstLastNameObj(invitation.employee),
          },
          establishments: _.union(
            (invitation.workplaces ?? []).map(
              (x) => x.establishment as ReferenceDto
            )
          ),
          workplaces: (invitation.workplaces ?? []).map((x) => x.workplace!),
          activities: (invitation.activities ?? []).map(
            (x) => x.activity as ReferenceDto
          ),
          phone: person.phone ?? '',
          email: person.email,
          pin: pinRes.data.pin ?? '',
          trainings: (person.trainings ?? []).filter(
            (x) => !invitationTrainingIsCompleted(x)
          ),
          note: tourniquetInvitation?.id === invitationId ? tourniquetInvitation?.note  ?? '' : '',
        });
      } catch (err) {
        setErrorState(err, setLoadError);
      }
      setLoading(false);
    })();
  }, [invitationId, tourniquetIdentity?.data?.invitation]);

  const handleRunTraining = async () => {
    setSaveError('');
    setIsSaving(true);
    try {
      const allPersons = [
        { id: data!.personId, name: getFirstLastNameObj(data) },
      ];

      const trainings: TourniquetResponse = {
        invitationPerson: tourniquetGetInvitationPerson(),
        trainings: data?.trainings,
      };

      //No trainings - error.
      if ((trainings.trainings?.length ?? 0) === 0) {
        throw getCustomError('noTrainings');
      }

      await tourniquetLoadData(
        invitationId!,
        trainings.trainings![0].training!.id,
        companyCustomization!,
        identity.companyId,
        allPersons,
        dispatch
      );


      dispatch(
        tourniquetIdentityActions.success({
          allData: [],
          data: {
            invitation: {
              id: invitationId!,
              name: data!.invitationName,
              note: formikRef.current?.values?.note,
            },
          },
          allPersons,
        })
      );

      dispatch(tourniquetTrainingsActions.success(trainings));
    } catch (err) {
      setErrorStateSnacks(err, setSaveError, enqueueErrorSnackbar);
    }
    setIsSaving(false);
  };

  const handleSubmit = async ({ note }: VisitationVisitDetailFormData) => {
    setSaveError('');
    try {
      const data2: VisitationPinDto = {
        invitation: invitationId!,
        pin: data!.pin,
        note,
      };

      await visitationApi.visit(identity.companyId, data2);
      enqueueSuccessSnackbar(t('common.saveSuccess'));

      dispatch(visitationListActions.reload(true));
      dispatch(invitationListActions.reload(true));
      dispatch(invitationTypeVisitListActions.reload(true));

      goBack();
    } catch (err) {
      setErrorStateSnacks(err, setSaveError, enqueueErrorSnackbar);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (loadError) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title={data!.title} backTo={backTo} />
      <FormikDebounce<VisitationVisitDetailFormData>
        initialValues={data!}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormContainer>
              <Input
                name="pin"
                label={t('visitation.pin')}
                required
                disabled
              />
              <Input
                name="note"
                label={t('common.notes')}
              />

              <SaveError error={saveError} />
              <ButtonsContainer>
                <QcsLoadingButton
                  variant="contained"
                  onClick={handleRunTraining}
                  loading={isSaving}
                  disabled={!data?.trainings.length || isSubmitting}
                >
                  {t('visitation.oneTimeRunTraining')}
                </QcsLoadingButton>
                <SubmitButton
                  disabled={isSaving}
                  text={t('visitation.oneTimeSave')}
                />
              </ButtonsContainer>
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
